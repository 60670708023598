@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  min-width: 320px;
  scroll-behavior: smooth;
}

html,
body {
  height: 100vh;
}

@layer base {
  a {
    @apply underline text-blue-600 hover:text-blue-500 focus:text-blue-500 visited:text-blue-700;
  }
}

.error-message {
  @apply text-sm text-red-500 pt-3;
}

.form-input {
  @apply bg-neutral-50 border border-neutral-300 placeholder-neutral-500 text-neutral-900 focus:outline-none focus:ring-black focus:border-black focus:ring-1 block w-full p-2.5 outline-1;
}

.form-input--error {
  @apply border-red-500 focus:border-red-500 focus:ring-red-500;
}

.form-title {
  @apply font-medium text-lg;
}

.form-description {
  @apply text-neutral-700;
}

.form-item {
  @apply flex items-center gap-2 cursor-pointer;
}

.form-list {
  @apply flex gap-2 items-center p-4 cursor-pointer hover:bg-neutral-50;
}

.form-list input {
  @apply accent-black;
}

.button {
  @apply py-3 px-8 inline-flex items-center justify-center gap-2;
}

.button--dark {
  @apply button bg-black text-white rounded-3xl hover:bg-neutral-900 focus:outline-neutral-300;
}

.button--light {
  @apply button text-black ring-1 ring-black rounded-3xl hover:bg-neutral-100 focus:outline-neutral-300;
}

.button--red {
  @apply button text-red-700 ring-1 ring-red-700 rounded-3xl hover:bg-neutral-100 focus:outline-neutral-300;
}

.close-button {
  @apply bg-black rounded-full p-2 inline-flex items-center justify-center text-white hover:text-neutral-500 hover:bg-neutral-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neutral-500 absolute -top-2 -right-2 cursor-pointer;
}

input:checked + div {
  @apply border-black bg-black;
}

input:checked + div svg {
  @apply block;
}

input:checked + label.active-color {
  @apply text-white border-black bg-black;
}

.toggle-checkbox:checked {
  @apply right-0 border-black;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-black;
}

.login-label > span {
  @apply font-bold whitespace-nowrap;
}

.tabs > a {
  @apply border-b-2 font-medium text-sm whitespace-nowrap pb-4 px-1 no-underline hover:text-neutral-900 visited:text-neutral-900;
}

#blip-chat-open-iframe {
  width: 0 !important;
  height: 0 !important;
  pointer-events: none;
  visibility: hidden !important;
}
